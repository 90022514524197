import { useEffect, useState } from "react";
import LoadingTwitter from "../LoadingComponents/TwitterLoading";
import "./style.css";
import { Link } from "react-router-dom";
import ShoeFeedLoading from "../LoadingComponents/ShoeFeedLoading";

export default function ShoeFeed() {
  const [shoeData, setShoeData] = useState([]);
  const [loaded, setLoaded] = useState();

  async function getTweets() {
    try {
      setLoaded(false);
      await fetch("/api/search/yeezy", {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((data) => {
          setShoeData(data);
          setLoaded(true);
        });
    } catch (err) {
      console.log(err);
      return;
    }
  }

  useEffect(() => {
    getTweets();
  }, []);

  if (loaded) {
    return (
      <section className="other-main-holder">
        <div className="other-holder">
          {shoeData.map((shoe) => {
            return (
              <Link
                key={location.href}
                to={{
                  pathname: `/result/${shoe.brand}?style=${shoe.styleID}&make=${shoe.silhoutte}`,
                }}
                className="shoe-item"
              >
                <img src={shoe.thumbnail} loading="lazy"></img>
                <h3>{shoe.shoeName}</h3>
              </Link>
            )
          })}
        </div>
      </section>
    );
  } else {
    return <ShoeFeedLoading/>
  }

}
