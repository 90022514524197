export default function ShoeFeedLoading() {
  var images = ["/DunkPlaceHolder.png", "/JordanPlaceHolder.png"];
  var image = images[Math.floor(Math.random() * images.length)];

  const fakeShoeData = ['', '', '', '', '', '', '']

  return (
    <section className="other-main-holder">
      <div className="other-holder">
        {fakeShoeData.map((shoe) => {
          return (
            <div className="shoe-item">
              <img className="loading-image" src={image} loading="lazy"></img>
              <h3>Loading</h3>
            </div>
          )
        })}
      </div>
    </section>
  );
}
